<!-- <app-main-que-header></app-main-que-header> -->
<!-- <ng-http-loader></ng-http-loader> -->
<section class="section grey_bg">
    <div class="container" [@flyInOut]>
        <div class="row">
            <div class="col-12">
                <div class="que_pagination_container">
                    <div class="que_pagination">
                        <ul>
                            <li class="active"><span>1</span></li>
                            <li class="active"><span>2</span></li>
                            <li class="active"><span>3</span></li>
                            <li class="active"><span>4</span></li>
                            <li class="active"><span>5</span></li>
                            <li class="active"><span>6</span></li>
                            <li><span>7</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3"></div>
            <div class="col-lg-6" [@flyInOut]>
                <!-- <ngx-spinner></ngx-spinner> -->
                <div class="min_height_funda">
                    <h1 class="que_title">{{ question }}</h1>
                    <p class="que_desc">{{ description }}</p>
                    <p class="que_desc notebox" style="color: red;"></p>
                </div>
                <div class="table-responsive">
                    <table width="100%" class="theme_table sixque">
                        <tr>
                            <th style="width: 60%;"></th>
                            <th>This </th>
                            <th>That</th>
                        </tr>
                        <tr *ngFor="let item of itemcounter;  let i = index">
                            <td>{{ items[item]['this'] }} or {{ items[item]['that'] }}</td>
                            <td>
                                <div class="form-check custom_check_div">
                                    <input class="form-check-input" type="radio" name="thisthat_{{ i }}" id="this{{ i }}" value="{{ items[item]['this'] }}" (click)="radiobuttoncheck(i,  items[item]['this'], items[item]['_id'], 'this' );">
                                    <label class="custom_radio"></label>
                                    <!-- <label class="form-check-label" for="this{{ i }}">Customer</label> -->
                                </div>
                            </td>
                            <td>
                                <!-- <div class="form-check custom_check_div">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" checked="" id="Customer1" value="Customer1">
                                    <label class="custom_radio"></label>
                                    <label class="form-check-label" for="Customer1">Customer</label>
                                </div> -->
                                <div class="form-check custom_check_div">
                                    <input class="form-check-input" type="radio" name="thisthat_{{ i }}" id="that{{ i }}" value="{{ items[item]['that'] }}" (click)="radiobuttoncheck(i,  items[item]['that'],  items[item]['_id'], 'that' );">
                                    <label class="custom_radio"></label>
                                    <!-- <label class="form-check-label" for="that_{{ i }}">Customer</label> -->
                                </div>

                            </td>
                        </tr>
                    </table>
                </div>
                <div class="next_perv_btndiv">
                    <div class="row">
                        <div class="col-sm-6 text-center text-sm-left">
                            <button (click)="perviousPageRedirect()" class="theme_btn next_prev_btn prev_btn"><i class="fas fa-arrow-left"></i> Previous</button>
                        </div>
                        <div class="col-sm-6 text-center text-sm-right">
                            <button id="next" (click)="nextPageRedirect()" class="theme_btn next_prev_btn next_btn">next <i class="fas fa-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3"></div>
        </div>
    </div>
</section>
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Helpers } from "../helper";
import { map } from 'rxjs/operators';
import { Subject } from "rxjs";


const httpOptions = {
  headers: new Headers({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: "root" })
export class HomeService {
  constructor(private http: HttpClient, private router: Router) {

  }

  getHomeSetting() {
    console.log(Helpers.apipath);
     return this.http.get(Helpers.apipath+'homesetting');
  }

  postRealtorData(item) {
    this.http.post<any>(Helpers.apipath+'matchrealtor', item);
    //  return this.http.post(Helpers.apipath+'matchrealtor');
  }

  getIPAddress()
  {
    return this.http.get("https://api.ipify.org/?format=json");
  }
}

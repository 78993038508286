import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { HttpClientModule } from '@angular/common/http';
import {HomeService} from "../service/home.service";
import {QuestionService} from "../service/question.service";
import { RouterModule, Router, ActivatedRoute , NavigationEnd} from '@angular/router';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

// import { HttpClientModule } from '@angular/common/http';


// import { AuthService } from "../auth/auth.service";

@Component({
  selector: "app-main",
  templateUrl: "./matchajent.component.html",
  styleUrls: ["./matchajent.component.css"],

})
export class MatchAjentComponent implements OnInit   {

  home_banner_bannerimage:any = '';
  home_banner_first_tag_line:any = '';
  home_banner_second_tag_line:any = '';
  home_banner_button_text:any = '';
  home_howwork_title:any = '';
  home_homework_bannerimage:any = '';
  home_realtor_banner_bannerimage:any = '';
  home_information_get_pre_approval:any = '';
  currentUrl: string;
  param1:string;
  realtordata:any;
  statedata:any;
  starRating:any;
  greeting_message:string;
  experiencedata:any;
  genderdata:any;
  personalitydata:any;
  intrestedselectedarray:any;
  thisthatarray:any;
  realtorthisthatarray:any;
  video:any;
  youtubestatus:boolean = false;
  youtubestatusNum:any = 0;
  closeResult: string;
  twitter_url: string = 'https://twitter.com/intent/tweet?url=';
  pintrest_url: string = 'https://www.pinterest.com/pin/create/button/?url=';
  reddit_url:string = 'https://www.reddit.com/submit?url='
  current_url:string ;

  //pinterest.com/pin/create/button/?url=https://www.somedomain.com/mypage&media=http://www.somedomain.com/assets/images/myimage.jpg&description=Some%20urlencoded%20description" data-pin-do="buttonPin" data-pin-config="beside"

  constructor(private homeservice: HomeService,
    private router :Router,
    private route: ActivatedRoute,
    private questionservice:QuestionService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private spinner: SpinnerVisibilityService,
    private title: Title) {
    router.events.subscribe((event: any) => {
      // spinner.show();
      if (event instanceof NavigationEnd) {
          this.currentUrl = event.url;
          let urldata = this.currentUrl.split("/");
          this.param1 = urldata[2];
        }
    });
  }

  ngOnInit() {
    $('.homeheader').show();
    $('.headerhomehide').show();
    $('html,body').scrollTop(0);

    this.twitter_url = this.twitter_url+ window.location.href;
    this.pintrest_url = this.pintrest_url+'https://'+ location.hostname +'&media=https://hellorea.cityworthhomes.com/admin/public/images/newbanner_img-org.jpg&description=Match%20City%20Worth%20is%20worlds%20first%20platform%20to%20provide%20agents%20with%20your%20matching%20criteria.';
    this.current_url = window.location.href;
    this.reddit_url = this.reddit_url + window.location.href;
    this.title.setTitle("Agent Profile")

    this.questionservice.getAjentDetail(this.param1).subscribe(data=>{
      if(data['status'] == true) {
        this.realtordata = data['realtordata'];
        if(this.realtordata[0]['video'] != '') {
          if(this.realtordata[0]['video'].search('www.youtube.com') != -1) {
            var  videoId =this.getId(this.realtordata[0]['video']);
            this.video =  this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+ videoId);
            this.youtubestatus = true;
            this.youtubestatusNum = 1;
          } else {
            this.video = this.realtordata[0]['video'];
            this.youtubestatus = false;
            this.youtubestatusNum = 0;
          }
        } else {
          this.video = this.realtordata[0]['video'];
          this.youtubestatus = false;
          this.youtubestatusNum = 0;
        }
        if(this.realtordata[0]['greeting_message']){
          let name = this.realtordata[0]['first_name']+" "+this.realtordata[0]['last_name'];
          let g_message = this.realtordata[0]['greeting_message'];
          g_message = g_message.replace("{{agentname}}","<span>"+name+"</span>");

          this.greeting_message = g_message;

          document.getElementById('congo_text_print').innerHTML = g_message;
        }
        if(this.realtordata[0]['ratings'] && this.realtordata[0]['ratings'] > 0){
          let rating = this.realtordata[0]['ratings'];

          let starPercentage = (rating/5)*100;

          this.starRating = starPercentage;

          console.log(starPercentage);
        }

//         const videoId = getId('http://www.youtube.com/watch?v=zbYf5_S7oJo');
// const iframeMarkup = '<iframe width="560" height="315" src="//www.youtube.com/embed/'
//     + videoId + '" frameborder="0" allowfullscreen></iframe>';

// console.log('Video ID:', videoId)


        this.statedata = data['stateselectedarray'];
        this.experiencedata = data['experience'];
        this.genderdata = data['gender'];
        this.personalitydata = data['personalityselectedarray'];
        this.intrestedselectedarray = data['intrestedselectedarray'];
        this.thisthatarray = data['thisthat'];
        this.realtorthisthatarray = data['realtorthisthatdata'];
      } else {
        this.router.navigate(['']);
      }
    });
    localStorage.removeItem('firstquestion');
    localStorage.removeItem('secondquestion');
    localStorage.removeItem('thirdquestion');
    localStorage.removeItem('fourthquestion');
    localStorage.removeItem('fifthquestion');
    localStorage.removeItem("sixquestionid");
    localStorage.removeItem("sixquestionname")
    localStorage.removeItem("sixquestiondbid");
    localStorage.removeItem("sixquestionvalue");
    localStorage.removeItem('first_name' );
    localStorage.removeItem('last_name');
    localStorage.removeItem('email_id');
    localStorage.removeItem('country_code');
    localStorage.removeItem('phone');

    this.homeservice.getHomeSetting().subscribe(data => {
      console.log( data['data']);
      data['data'].forEach(element => {
        if(element.text_key == "home_banner_bannerimage") {
          this.home_banner_bannerimage = element.text_value;
        }
        if(element.text_key == "home_banner_first_tag_line") {
          this.home_banner_first_tag_line = element.text_value;
        }
        if(element.text_key == "home_banner_second_tag_line") {
          this.home_banner_second_tag_line = element.text_value;
        }

        if(element.text_key == "home_banner_button_text") {
          this.home_banner_button_text = element.text_value;;
        }

        if(element.text_key == "home_howwork_title") {
          this.home_howwork_title = element.text_value;;
        }

        if(element.text_key == "home_homework_bannerimage") {
          this.home_homework_bannerimage = element.text_value;
        }
        if(element.text_key == "home_realtor_banner_bannerimage") {
          this.home_realtor_banner_bannerimage = element.text_value;
        }

        if(element.text_key == "home_information_get_pre_approval") {
          this.home_information_get_pre_approval = element.text_value;
        }
      });
    });
    setTimeout (() => {
      // this.spinner.hide();
   }, 3000);

  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openandplay(content) {
    // $("#playvideo")[0].src += "?autoplay=1";
    console.log($("#playvideo"))
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
}


}

<!-- <ng-http-loader></ng-http-loader> -->
<app-main-home-header></app-main-home-header>
<div class="homepage">
        <div [ngStyle]="{'background': 'url(' + home_banner_bannerimage + ') no-repeat 0 0 / cover'}" class="home_banner bg">
            <div class="container">
                <div class="home_banner_content">
                    <h1 class="banner_title wow zoomIn">{{ home_banner_first_tag_line }}</h1>
                    <p class="banner_text">{{ home_banner_second_tag_line }}</p>
                    <ng-template [ngIf]="show_home_header == true">
                        <button class="theme_btn big_btn que_btn wow zoomIn" (click)="nextPageRedirect();">{{ home_banner_button_text }}</button>
                    </ng-template>
                </div>
            </div>
        </div>
    <section class="section" id="homwwork" fragment="homwwork" #howwork>
        <div class="container text-center">
            <h2 class="section_title wow zoomIn">{{ home_howwork_title }}</h2>
            <img [src]="home_homework_bannerimage" class="how_it_work_img">
        </div>
    </section>
</div>
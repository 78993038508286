import { Component, OnInit, OnDestroy, NgModule, AfterViewChecked} from "@angular/core";
import {QuestionService} from '../service/question.service';
import {HomeService} from '../service/home.service';
import { RouterModule, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Helpers } from "../helper";
import { SpinnerVisibilityService } from 'ng-http-loader';
import { splitAtPeriod } from '@angular/compiler/src/util';
import { NgxSpinnerService } from "ngx-spinner";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes,
  group
} from '@angular/animations';
@Component({
  selector: "app-main-que",
  templateUrl: "./firstquestion.component.html",
  styleUrls: ["./firstquestion.component.css"],
  animations: [
    trigger('flyInOut',[
        transition("void => *",[
            style({opacity:'0'}),
            animate('2s')
          ]),
        transition("* => void",[
            animate('1s',style({transform:"translateX(100%)"}))
          ])

      ])
  ]
})
export class FirstQuestionComponent implements OnInit, AfterViewChecked {
  question:any = '';
  description:any = '';
  ip_address:any = '';
  items:any = [];
  itemcounter:any = [];
  status:boolean=true;
  href:any;
  hrefarray:any;
  hreffinalarray:any;
  headershowstatus:boolean = false;
  isloading:boolean = true;

  constructor(
    private questionservice: QuestionService,
    private router :Router,
    private homeservice :HomeService,
    private http: HttpClient,
    // private spinner: SpinnerVisibilityService,
    private spinner: NgxSpinnerService) {
      
      // debugger;
      spinner.show();
      this.getFirstQuestion();
  }

  ngOnInit() {
    $('.notebox').text('');
    $('.homeheader').show();
    $('html,body').scrollTop(0);
    this.isloading = true;
    this.href = window.location.href;
    // console.log('href', this.href);
    this.hrefarray= this.href.split('#');
    this.hreffinalarray = this.hrefarray[1].split('/');

    if(this.hreffinalarray[1] == '') {
     this.headershowstatus = true;
    } else {
     this.headershowstatus = false;
    }
    console.log('headerstatus', this.headershowstatus);
    // this.getFirstQuestion();
    (<any>$('#next')).prop('disabled', true);
    // if(localStorage.getItem("firstquestion") != '') {
    //   (<any>$('#next')).prop('disabled', false);
    //   var firsqueidval = localStorage.getItem("firstquestion");
    //   console.log(firsqueidval);
    //   (<any>$('.selectbutton_5f2a95012bcf3e70f49f9d08')).css("background-color", "#ff8033");

    // }

    // this.spinner.hide();
  }

  ngAfterViewChecked() {
    if(localStorage.getItem("firstquestion") != '') {
      (<any>$('#next')).prop('disabled', false);
      var firsqueidval = localStorage.getItem("firstquestion");
      console.log(firsqueidval);
      (<any>$('.selectbutton_'+firsqueidval)).css("background-color", "#ff8033");
      (<any>$('.selectbutton_'+firsqueidval)).css("color", "#fff");
    }

  }

  selectedButton(id, index) {
    (<any>$('#next')).prop('disabled', false);
    localStorage.setItem("firstquestion", '');
    (<any>$('.unselectbutton')).css("background-color", "");
    (<any>$('.selectbutton_'+ id)).css("background-color", "#ff8033");
    (<any>$('.unselectbutton')).css("color", "");
    (<any>$('.selectbutton_'+ id)).css("color", "#fff");
    localStorage.setItem("firstquestion", id);
  }

  nextPageRedirect() {
    this.spinner.show();
    var selectid = localStorage.getItem("firstquestion");
    if(selectid == null || selectid == 'null') {
      this.spinner.hide();
      $('.notebox').text('Please select any option to move ahead');
    }

    this.homeservice.getIPAddress().subscribe(data=>{
     localStorage.setItem('ip_address', data['ip']);
     this.ip_address = data['ip'];
     var firstquestionarray= {
      ip_address: this.ip_address,
      firstquestion: selectid,
     };
     this.questionservice.addFirstQuestion(firstquestionarray).subscribe(data => {
       if(data.status == true) {
         localStorage.setItem("matchajentid", data.data._id);
          this.questionservice.getFirstQuestion().subscribe(data => {
            data['optionData'].forEach(element => {
              if(element._id == selectid) {
                if(element.ask_gender_in_next_step == 'Yes') {
                  localStorage.removeItem('firstquestiongender');
                  this.router.navigate(['secondquestion']);
                } else {
                  localStorage.setItem("firstquestiongender", 'no');
                  this.router.navigate(['thirdquestion']);
                  localStorage.removeItem("secondquestion");
                }
              }
            });
          });
       }
    });

   });



  }

  getFirstQuestion() {
    this.spinner.show();
    this.questionservice.getFirstQuestion().subscribe(data => {
      this.isloading = false;
      this.question = data['question'][0].question;
      this.description = data['question'][0].description;
      this.items = data['optionData'];
      this.spinner.hide();
      for(var i = 0; i<=this.items.length; i++) {
        if(i != this.items.length) {
          this.itemcounter.push(i);
        }
      }
    });
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.href = window.location.href;
    // console.log('href', this.href);
    this.hrefarray= this.href.split('#');
    this.hreffinalarray = this.hrefarray[1].split('/');

    if(this.hreffinalarray[1] == '') {
     this.headershowstatus = true;
    } else {
     this.headershowstatus = false;
    }
  }

  perviousPageRedirect() {
    this.spinner.show();
    this.router.navigate(['/']);
  }
}


import { Component, OnInit, OnDestroy, NgModule, AfterViewChecked } from "@angular/core";
import {QuestionService} from '../service/question.service';
import { RouterModule, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { NgxSpinnerService } from "ngx-spinner";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes,
  group
} from '@angular/animations';
@Component({
  selector: "app-main-que",
  templateUrl: "./thirdquestion.component.html",
  styleUrls: ["./thirdquestion.component.css"],
  animations: [
    trigger('flyInOut',[
        transition("void => *",[
            style({opacity:'0'}),
            animate('2s')
          ]),
        transition("* => void",[
            animate('1s',style({transform:"translateX(100%)"}))
          ])

      ])
  ]
})
export class ThirdQuestionComponent implements OnInit, AfterViewChecked {

  question:any = '';
  description:any = '';
  items:any = [];
  itemcounter:any = [];
  status:boolean=true;

  constructor(private questionservice: QuestionService, 
    private router:Router,
    private spinner: NgxSpinnerService) {
    this.questionservice.getThirdQuestion().subscribe(data => {
      this.spinner.hide();
      this.question = data['question'][0].question;
      this.description = data['question'][0].description;
      this.items = data['optionData'];

      for(var i = 0; i<=this.items.length; i++) {
        if(i != this.items.length) {
          this.itemcounter.push(i);
        }
      }
    });
    spinner.show();
  }

  ngOnInit() {
    $('.notebox').text('');
    $('.homeheader').show();

    $('html,body').scrollTop(0);
    if(localStorage.getItem('firstquestion') == 'null' || localStorage.getItem('firstquestion') == null) {
      this.router.navigate(['']);
    }
    // this.questionservice.getThirdQuestion().subscribe(data => {
    //   this.spinner.hide();
    //   this.question = data['question'][0].question;
    //   this.description = data['question'][0].description;
    //   this.items = data['optionData'];

    //   for(var i = 0; i<=this.items.length; i++) {
    //     if(i != this.items.length) {
    //       this.itemcounter.push(i);
    //     }
    //   }
    // });
  }

  ngAfterViewChecked() {
    if(localStorage.getItem("thirdquestion") != '') {
      (<any>$('#next')).prop('disabled', false);
      var firsqueidval = localStorage.getItem("thirdquestion");
      (<any>$('.selectbutton_'+firsqueidval)).css("background-color", "#ff8033");
      (<any>$('.selectbutton_'+firsqueidval)).css("color", "#fff");
    }
  }

  perviousPageRedirect() {
    this.spinner.show();
    if(localStorage.getItem('firstquestiongender') == 'no')   {
      this.router.navigate(['firstquestion']);
    } else {
      this.router.navigate(['secondquestion']);
    }
  }

  selectedButton(id, index) {
    (<any>$('#next')).prop('disabled', false);
    localStorage.setItem("thirdquestion", '');
    (<any>$('.unselectbutton')).css("background-color", "");
    (<any>$('.selectbutton_'+ id)).css("background-color", "#ff8033");
    (<any>$('.unselectbutton')).css("color", "");
    (<any>$('.selectbutton_'+ id)).css("color", "#fff");
    localStorage.setItem("thirdquestion",id);
  }

  nextPageRedirect() {
    this.spinner.show();
    var selectid = localStorage.getItem("thirdquestion");
    if(selectid == null || selectid == 'null') {
      this.spinner.hide();
      $('.notebox').text('Please select any option to move ahead');
    }

    var ip_address = localStorage.getItem("ip_address");
    var matchajentid = localStorage.getItem("matchajentid");
    var dataarray = {
      ip_address: ip_address,
      _id : matchajentid,
      thirdquestion:selectid,
    }

    this.questionservice.updatethirdQuestion(dataarray).subscribe(result =>{
      if(result.status == true) {
        this.spinner.hide();
        this.questionservice.getThirdQuestion().subscribe(data => {
          data['optionData'].forEach(element => {
            if(element._id == selectid) {
              this.router.navigate(['fourthquestion']);
            }
          });
        });
      }
    });
  }
}


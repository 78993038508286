import { Component, OnInit, OnDestroy, NgModule, AfterViewChecked } from "@angular/core";
import {QuestionService} from '../service/question.service';
import { RouterModule, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes,
  group
} from '@angular/animations';
@Component({
  selector: "app-main-que",
  templateUrl: "./fourthquestion.component.html",
  styleUrls: ["./fourthquestion.component.css"],
  animations: [
    trigger('flyInOut',[
        transition("void => *",[
            style({opacity:'0'}),
            animate('2s')
          ]),
        transition("* => void",[
            animate('1s',style({transform:"translateX(100%)"}))
          ])

      ])
  ]
})
export class FourthQuestionComponent implements OnInit, AfterViewChecked {

  question:any = '';
  description:any = '';
  items:any = [];
  itemcounter:any = [];
  selectoptionarray:any = [];
  status:boolean=true;
  closeResult: string;
  question_counter:any = '';

  constructor(
    private questionservice: QuestionService,
    private router:Router,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal) {
    spinner.show();
  }

  ngOnInit() {
    $('.notebox').text('');
    $('.homeheader').show();

    $('html,body').scrollTop(0);

    this.question_counter = '0';
    this.getFourthQuestion();
    // (<any>$('#next')).prop('disabled', true);
    // if(localStorage.getItem("secondquestion") != '') {
    //   (<any>$('#next')).prop('disabled', false);
    //   var firsqueidval = localStorage.getItem("secondquestion");
    // }

    if(localStorage.getItem('firstquestion') == 'null' || localStorage.getItem('firstquestion') == null) {
      this.router.navigate(['']);
    }

    var fourthquelist = localStorage.getItem("fourthquestion");
    if(fourthquelist != '') {
      var fourthquearray = fourthquelist.split(",");

      this.question_counter = fourthquearray.length;

      fourthquearray.forEach(element => {

        this.selectoptionarray.push(element);
      });
    }
    // this.spinner.hide();
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    
  }

  selectedButton(id, index, mymodal) {


    // if(this.selectoptionarray.length != 7) {
    //   (<any>$('#next')).prop('disabled', true);
    // }



    if(this.selectoptionarray.includes(id) == true) {
      this.selectoptionarray.splice(this.selectoptionarray.indexOf(id), 1);
      (<any>$('.selectbutton_'+ id)).css("background-color", "#ffffff");
      (<any>$('.selectbutton_'+ id)).css("color", "#777676");
      localStorage.setItem("fourthquestion", this.selectoptionarray);

      if(this.selectoptionarray.length != 7) {
        // (<any>$('#next')).prop('disabled', true);
      }



      var fourthquelist = localStorage.getItem("fourthquestion");
      if(fourthquelist == '') {
        this.question_counter = 0;
      } else {
        var fourthquearray = fourthquelist.split(",");
        this.question_counter = fourthquearray.length;
      }


    } else {

      // var fourthquelist = localStorage.getItem("fourthquestion");
      // var fourthquearray = fourthquelist.split(",");

      // if(fourthquearray.length <= 7) {
        if(this.question_counter != '7') {
          this.selectoptionarray.push(id);
          (<any>$('.selectbutton_'+ id)).css("background-color", "#ff8033");
          (<any>$('.selectbutton_'+ id)).css("color", "#777676");
          localStorage.setItem("fourthquestion", this.selectoptionarray);
        } else {
          (<any>$('.selectbutton_'+ id)).css("background-color", "#ffffff");
          (<any>$('.selectbutton_'+ id)).css("color", "#777676");
          // this.open(mymodal);
          // alert('You have already selected 7 options please unselect one to choose another');
          $('.notebox').text('You have already selected 7 options. Please unselect one to choose another');
        }



      // }

      // if(this.selectoptionarray.length <= 7) {

      // }

      var fourthquelist = localStorage.getItem("fourthquestion");
      var fourthquearray = fourthquelist.split(",");
      this.question_counter = fourthquearray.length;


    }
    if(this.selectoptionarray.length == 7) {
      // (<any>$('#next')).prop('disabled', false);
    }

  }

  nextPageRedirect() {
    this.spinner.show();
    if(this.selectoptionarray.length == 7) {
      if( localStorage.getItem("fourthquestion") != '' && localStorage.getItem("fourthquestion") != null && localStorage.getItem("fourthquestion") != 'null' ) {
        var fourthquelist = localStorage.getItem("fourthquestion")
        var ip_address = localStorage.getItem("ip_address");
        var matchajentid = localStorage.getItem("matchajentid");
        var dataarray = {
          ip_address: ip_address,
          _id : matchajentid,
          fourthquestion:fourthquelist,
        }

        this.questionservice.updatefourthQuestion(dataarray).subscribe(result =>{
          if(result.status == true) {
            this.router.navigate(['fifthquestion']);
          }
        });
      } else {
        this.spinner.hide();
        $('.notebox').text('Please select at-least 7 options to move ahead');
      }
    }

    if(this.selectoptionarray.length != 7) {
      this.spinner.hide();
      $('.notebox').text('Please select at-least 7 options to move ahead');
    }
  }

  ngAfterViewChecked() {
    if(localStorage.getItem("fourthquestion") != '') {
      // (<any>$('#next')).prop('disabled', false);
      var fourthquelist = localStorage.getItem("fourthquestion");

          if(fourthquelist != '') {
            var fourthquearray = fourthquelist.split(",");

            this.question_counter = fourthquearray.length;
            if(fourthquearray.length == 7) {
              (<any>$('#next')).prop('disabled', false);
            }
            fourthquearray.forEach(element => {
              (<any>$('.selectbutton_'+element)).css("background-color", "#ff8033");
              (<any>$('.selectbutton_'+ element)).css("color", "#fff");
              // this.selectoptionarray.push(element);
            });
          }
      //
    }
  }


  perviousPageRedirect() {
    this.spinner.show();
    this.router.navigate(['thirdquestion']);
  }

  getFourthQuestion() {
    this.questionservice.getFourthQuestion().subscribe(data => {
      this.spinner.hide();
      this.question = data['question'][0].question;
      this.description = data['question'][0].description;
      this.items = data['optionData'];

      for(var i = 0; i<=this.items.length; i++) {
        if(i != this.items.length) {
          this.itemcounter.push(i);
        }
      }
    });
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}


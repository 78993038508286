import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { from } from 'rxjs';
import {HomeService} from './service/home.service';
import { RouterModule, Router, ActivatedRoute , NavigationEnd } from '@angular/router';
import {FirstQuestionComponent} from './firstquestion/firstquestion.component';
import {HomeComponent} from './home/home.component';
import { filter, map, mergeMap } from 'rxjs/operators';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'MatchCity';
  href:any;
  hrefarray:any;
  hreffinalarray:any;
  headershowstatus:boolean = false;
  home_seo_title:string = "";
  home_seo_description:string = "";
  home_banner_bannerimage:string = "";

  constructor(
    private seotitle: Title, 
    private meta: Meta, 
    private homeservice:HomeService, 
    private router: Router,
    private activatedRoute: ActivatedRoute
    ) {
    this.homeservice.getHomeSetting().subscribe(data => {
      console.log( data['data']);
      data['data'].forEach(element => {
        if(element.text_key == "home_faviconicon") {
          $(".home_faviconicon").attr("href",element.text_value);
        }
        if(element.text_key == "home_seo_title") {
          this.home_seo_title = element.text_value;
        }
        if(element.text_key == "home_seo_description") {
          this.home_seo_description = element.text_value;
        }
        if(element.text_key == "home_banner_bannerimage") {
          this.home_banner_bannerimage = element.text_value;
        }
      });
    });
  }

 ngOnInit() {
  this.seotitle.setTitle("Hello REA");
   this.href = window.location.href;
   console.log('hrefankit', this.href);
   this.hrefarray= this.href.split('#');
   this.hreffinalarray = this.hrefarray[1].split('/');
   console.log('hrefurl', this.hreffinalarray);
   this.homeservice.getHomeSetting().subscribe(data => {
     data['data'].forEach(element => {
        if(element.text_key == "home_seo_title") {
          this.home_seo_title = element.text_value;
          this.addMetaTags();
        }
        if(element.text_key == "home_seo_description") {
          this.home_seo_description = element.text_value;
          this.addMetaTags();
        }
        if(element.text_key == "home_banner_bannerimage") {
          this.home_banner_bannerimage = element.text_value;
          this.addMetaTags();
        }
      });
    });
   if(this.hreffinalarray[1] == '') {
    this.headershowstatus = true;
    console.log('mainheaderstatus', this.headershowstatus);
   } else {
    this.headershowstatus = false;
    console.log('mainheaderstatus', this.headershowstatus);
   }
   
 }
 updateTitle(title?: string) {
        if (!title) {
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    map(() => this.activatedRoute),
                    map((route) => {
                        while (route.firstChild) { route = route.firstChild; }
                        return route;
                    }),
                    filter((route) => route.outlet === 'primary'),
                    mergeMap((route) => route.data)).subscribe((event) => {
                        this.seotitle.setTitle(event['title'] + ' | Site name');
                    });
        } else {
            this.seotitle.setTitle(title + ' | Site name');
        }
    }
 addMetaTags(){
   this.meta.addTag({ property: 'og:title', content: this.home_seo_title });
   this.meta.addTag({ property: 'og:description', content: this.home_seo_description });
   this.meta.addTag({ property: 'og:image', content: this.home_banner_bannerimage });
   this.meta.addTag({ property: 'twitter:image', content: this.home_banner_bannerimage });
 }
 ngAfterViewInit(): void {
   
  this.href = window.location.href;
  console.log('href', this.href);
  this.hrefarray= this.href.split('#');
  this.hreffinalarray = this.hrefarray[1].split('/');

  if(this.hreffinalarray[1] == '') {
   this.headershowstatus = true;
  } else {
   this.headershowstatus = false;
  }

 }


}

import * as $ from "jquery";

export class Helpers {
    // static baseUrl = "http://localhost:3000/";
    static baseUrl ="https://hellorea.cityworthhomes.com:8002/";
    static liveUrl = Helpers.baseUrl + "";
    static apipath = Helpers.liveUrl + "api/";
    static adminApiPath = Helpers.baseUrl + "backend/api/";
    // static ImgPath = Helpers.liveUrl + "assets/images/";
    // static faviconPath = Helpers.liveUrl + "assets/images/favicon/";
    // static imagesource = "assets/images/";
    // static storyImgPath = Helpers.liveUrl + "assets/images/stories/";
    // static bannerImgPath = Helpers.baseUrl + "assets/images/headerImage/";
    // static boxImagePath = Helpers.baseUrl + "assets/images/boxImage/";
    // static boxVideoPath = Helpers.baseUrl + "assets/images/boxVideo/";
    // static sliderImgPath = Helpers.baseUrl + "assets/images/slideImage/";
    // static carouselImgPath = Helpers.baseUrl + "assets/images/carousel/";
    // static publicPath = Helpers.baseUrl + "public/";
    // static BlogImgPath = Helpers.baseUrl + "public/";
}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { HttpClientModule } from '@angular/common/http';
import {HomeService} from "../service/home.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit   {

  constructor(private homeservice: HomeService) {

  }

  ngOnInit() {


  }



}

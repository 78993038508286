<section class="section grey_bg">
    <div class="container">
        <div class="email_temp">
            <div class="mail_logo text-center">
                <img src="http://54.237.109.228:8002/images/logo.png-1594876303859.png">
            </div>
            <p class="mail_desc">This Customer can come to the website and fill the questionnaries based on that provided answer he is matching with the realtors that details is listed.</p>
            <div class="row">
                <div class="col-lg-1"></div>
                <div class="col-lg-10">
                    <div class="mail_box">
                        <h3>Customer Information :</h3>
                        <div class="white_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="lab_val">
                                        <p class="lab">Customer Name:</p>
                                        <p class="val">Rechard Walker</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="lab_val">
                                        <p class="lab">Email ID:</p>
                                        <p class="val">rechardWalker@mail.com</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="lab_val">
                                        <p class="lab">Phone:</p>
                                        <p class="val">+91 87288 45277</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="lab_val">
                                        <p class="lab">What state are you buying a home in?</p>
                                        <p class="val">Gujrat</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="lab_val">
                                        <p class="lab">What level of experience do you want your Realtor to have?</p>
                                        <p class="val">5 - 20 Closed Transactions</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="lab_val">
                                        <p class="lab">What personality traits do you want your Realtorto have?</p>
                                        <p class="val">Lorem Ipsum</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="lab_val">
                                        <p class="lab">What are your interests?</p>
                                        <p class="val">Lorem Ipsum</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="lab_val">
                                        <p class="lab">This or that?</p>
                                        <p class="val">Indoor</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mail_box">
                        <h3>Realtor Information :</h3>
                        <div class="white_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="lab_val">
                                        <p class="lab">Realtor Name:</p>
                                        <p class="val">Jessica Alba</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="lab_val">
                                        <p class="lab">Realtor ID:</p>
                                        <p class="val">951224</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="theme_btn big_btn orange_btn">View Agent Profile</button>
                    </div>
                </div>
                <div class="col-lg-1"></div>
            </div>
        </div>
    </div>
</section>
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule  } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { FirstQuestionComponent } from './firstquestion/firstquestion.component';
import { SecondQuestionComponent } from './secondquestion/secondquestion.component';
import { ThirdQuestionComponent } from './thirdquestion/thirdquestion.component';
import { FourthQuestionComponent } from './fourthquestion/fourthquestion.component';
import { FifthQuestionComponent } from './fifthquestion/fifthquestion.component';
import { SixQuestionComponent } from './sixquestion/sixquestion.component';
import { SevenQuestionComponent } from './sevenquestion/sevenquestion.component';
import { QuestionHeaderComponent } from './questionheader/questionheader.component';
import { AjentProfileComponent } from './ajentprofile/ajentprofile.component';
import { MatchAjentComponent } from './matchajent/matchajent.component';
import { HomeHeaderComponent } from './homeheader/homeheader.component';
import { NgxSocialShareModule } from 'ngx-social-share';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader'; //
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


// import { CeiboShare } from 'ng2-social-share';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    FirstQuestionComponent,
    SecondQuestionComponent,
    ThirdQuestionComponent,
    FourthQuestionComponent,
    FifthQuestionComponent,
    SixQuestionComponent,
    SevenQuestionComponent,
    QuestionHeaderComponent,
    AjentProfileComponent,
    MatchAjentComponent,
    HomeHeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSocialShareModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgHttpLoaderModule.forRoot(),
  ],
  providers: [Title],
  bootstrap: [AppComponent],


})
export class AppModule { }

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Helpers } from "../helper";
import { Router } from "@angular/router";


const httpOptions = {
  headers: new Headers({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: "root" })
export class QuestionService {

  constructor(private http: HttpClient, private router: Router) {

  }

  getFirstQuestion() {
     return this.http.get(Helpers.apipath+'question/firstquestion');
  }

  getSecondQuestion() {
    return this.http.get(Helpers.apipath+'question/secondquestion');
  }

  getThirdQuestion() {
    return this.http.get(Helpers.apipath+'question/thirdquestion');
  }

  getFourthQuestion() {
    return this.http.get(Helpers.apipath+'question/fourthquestion');
  }

  getFifthQuestion() {
    return this.http.get(Helpers.apipath+'question/fifthquestion');
  }

  getSixQuestion() {
    return this.http.get(Helpers.apipath+'question/sixquestion');
  }

  addFirstQuestion(data) {
    return this.http.post<any>(Helpers.apipath+'firstquestionoptionadd', data);
  }

  updateSecondQuestion(data) {
    return this.http.post<any>(Helpers.apipath+'secondquestionoptionupdate', data);
  }

  updatethirdQuestion(data) {
    return this.http.post<any>(Helpers.apipath+'thirdquestionoptionupdate', data);
  }

  updatefourthQuestion(data) {
    return this.http.post<any>(Helpers.apipath+'fourthquestionoptionupdate', data);
  }

  updatefifthQuestion(data) {
    return this.http.post<any>(Helpers.apipath+'fifthquestionoptionupdate', data);
  }

  updatesixQuestion(data) {
    return this.http.post<any>(Helpers.apipath+'sixquestionoptionupdate', data);
  }

  getAjentDetail(name) {
    return this.http.get(Helpers.apipath+'ajent/'+name);
  }
}

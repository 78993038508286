import { Component, OnInit, OnDestroy, NgModule, AfterViewChecked, ViewChild, ElementRef  } from "@angular/core";
import {HomeService} from '../service/home.service';
import { RouterModule, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';


@Component({
  selector: "app-main-home-header",
  templateUrl: "./homeheader.component.html",
  styleUrls: ["./homeheader.component.css"]
})
export class HomeHeaderComponent implements OnInit {
  @ViewChild('structure') public structure:ElementRef;

  logo:any = '';
  loading:boolean=true;
  href:any;
  hrefarray:any; 
  hreffinalarray:any;
  headershowstatus:boolean = false;


  constructor(private homeservice: HomeService, private router:Router, private spinner: SpinnerVisibilityService) {
    // spinner.show();

  }

  ngOnInit() {
    this.href = window.location.href;
    console.log('hrefankit', this.href);
    this.hrefarray= this.href.split('#');
    this.hreffinalarray = this.hrefarray[1].split('/');
    console.log('hrefurl', this.hreffinalarray);

    if(this.hreffinalarray[1] == '') {
     this.headershowstatus = true;
     console.log('mainheaderstatus', this.headershowstatus);
     $('.headerhomehide').hide();
    } else {
     this.headershowstatus = false;
     console.log('mainheaderstatus', this.headershowstatus);
    //  $('.headerhomehide').show();
    }

    this.homeservice.getHomeSetting().subscribe(data => {
      data['data'].forEach(element => {
        if(element.text_key == "home_websitelogo") {
          this.logo = element.text_value;
          var html = '<a href="#"><img src='+this.logo+' class="check" alt=" logo"></a>';
          $(".logo_img").html(html)
        }

      });
    });

  }
//   public moveToStructure() {
// this.router.navigate[''];
//     this.structure.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
// }

howwork() {
  this.router.navigate([''],  {fragment: 'homwwork'});
}







}


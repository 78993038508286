<!-- <ng-http-loader></ng-http-loader> -->
<div class="overlay" onclick="closeOverlay()"></div>
<nav class="navbar navbar-expand top_menu">
    <div class="container">
        <div class="logo_img">
            <!-- <a href="#"><img [src]="logo" alt=" logo"></a> -->
        </div>
        <!-- <a href="#x" id="slide" class="navbar-toggle">
            <i class="fas fa-bars mobile_menu_icon"></i>
        </a> -->
        <div class="collapse navbar-collapse navbar-collapse-md" id="navbarResponsive">
            <ul class="navbar-nav ml-auto">
                <!-- <li class="nav-item ">
                    <a href="#" class="nav-link hvr-underline-from-left ">
		            	Home
		          	</a>
                </li> -->
                <li class="nav-item headerhomehide" *ngIf="headershowstatus == false">
                    <a [routerLink]="['']" class="nav-link hvr-underline-from-left ">
                      How It Works
		          	</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<!-- <section>
    <div class="sideoff-off ">
        <div class="navbar-header ">
            <a class="navbar-toggle mobile-menu-style " id="slideclose ">
                <i class="fas fa-times "></i>
            </a>
        </div>
        <ul class="navbar-nav ml-auto ">
            <li class="nav-item ">
                <a [routerLink]="['']" class="nav-link hvr-underline-from-left ">
	            	How It work
	          	</a>
            </li>
        </ul>
    </div>
</section> -->

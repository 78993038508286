import { Component, OnInit, OnDestroy, NgModule, AfterViewChecked, AfterViewInit, AfterContentChecked } from "@angular/core";
import {QuestionService} from '../service/question.service';
import { RouterModule, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { NgxSpinnerService } from "ngx-spinner";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes,
  group
} from '@angular/animations';
@Component({
  selector: "app-main-que",
  templateUrl: "./sixquestion.component.html",
  styleUrls: ["./sixquestion.component.css"],
  animations: [
    trigger('flyInOut',[
        transition("void => *",[
            style({opacity:'0'}),
            animate('2s')
          ]),
        transition("* => void",[
            animate('1s',style({transform:"translateX(100%)"}))
          ])

      ])
  ]
})
export class SixQuestionComponent implements OnInit,   AfterContentChecked {

  question:any = '';
  description:any = '';
  items:any = [];
  itemcounter:any = [];
  selectoptionarray:any = [];
  thisarray:any= [];
  thatarray:any= [];
  thisthatnamearray:any= [];
  indexiarray:any = [];
  thisthatidarray:any = [];
  thisthatvaluearray:any = [];
  status:boolean=true;
  question_counter:any = '';
  totaloption:any ;

  constructor(private questionservice: QuestionService, private router:Router, private spinner: NgxSpinnerService) {
    spinner.show();
    var queindexid = localStorage.getItem("sixquestionid");
    var queoptionname = localStorage.getItem("sixquestionname");
    var questiondatabaseid = localStorage.getItem("sixquestiondbid");
    var questionvalue = localStorage.getItem("sixquestionvalue");
    if(queindexid != '' && queindexid != null) {
      var queindex = queindexid.split(",");
      var quesindex = queoptionname.split(",");
      var quesdatabaseindex = questiondatabaseid.split(",");
      var quesvalueindex = questionvalue.split(",");


      // console.log('ankit' + queindex.length);
      // for(var i = 0; i<=queindex.length - 1;i++) {

      //   // // if(queindex.length >= i) {
      //   //   var thisthatid = quesindex[i] +queindex[i];
      //   //   console.log(thisthatid);
      //   //   console.log(i);
      //   //   debugger;


      //   //   (<any>$('#'+thisthatid)).prop("checked", true);
      //   // // } else {
      //   // //   break;
      //   // // }

      // }
      // debugger;

      for(var i =0; i<=queindex.length - 1;i++) {

        var thisthatid = quesindex[i] +queindex[i];
        console.log(thisthatid);

        (<any>$('#'+thisthatid)).attr('checked', true);
      }
    }
  }

  ngOnInit() {
    $('.notebox').text('');
    $('.homeheader').show();

    $('html,body').scrollTop(0);
    if(localStorage.getItem('firstquestion') == 'null' || localStorage.getItem('firstquestion') == null) {
      this.router.navigate(['']);
    }
    // (<any>$('#next')).prop('disabled', true);

    this.questionservice.getSixQuestion().subscribe(data => {
      this.spinner.hide();
      this.question = data['question'][0].question;
      this.description = data['question'][0].description;
      this.items = data['optionData'];
      this.totaloption = this.items.length;


      if(localStorage.getItem("sixquestionid") != '') {
        var itemcount = localStorage.getItem("sixquestionid");
        if(itemcount != '' && itemcount != null) {
          var itemcountarray = itemcount.split(",");
          if(itemcountarray.length >=  this.totaloption) {
            (<any>$('#next')).prop('disabled', false);
          }
        }
      }

      for(var i = 0; i<=this.items.length; i++) {
        if(i != this.items.length) {
          this.itemcounter.push(i);
        }
      }
    });


    if(localStorage.getItem("sixquestionid") != '') {
      var queindexid = localStorage.getItem("sixquestionid");
      var queoptionname = localStorage.getItem("sixquestionname");
      var quedatabaseid = localStorage.getItem("sixquestiondbid");
      var quename = localStorage.getItem("sixquestionvalue");

      if(queindexid != '' && queindexid != null) {
        var queindex = queindexid.split(",");
        var quesindex = queoptionname.split(",");
        var quesidindex = quedatabaseid.split(",");
        var quesnameindex = quename.split(',');

        for(var u= 0;u<=queindex.length -1;u++){
          this.indexiarray.push(parseInt(queindex[u]));
          this.thisthatnamearray.push(quesindex[u]);
          this.thisthatvaluearray.push(quesnameindex[u]);
          this.thisthatidarray.push(quesidindex[u]);
        }
      }




      // this.indexiarray.push(i);
      // this.thisthatnamearray.push(thisthatname);
      // this.thisthatidarray.push(quesidindex);
      // console.log(  this.thisthatidarray);
      // debugger;
      // this.thisthatvaluearray.push(value);


      // for(var i =0; i<=queindex.length-1;i++) {
      //   var thisthatid = quesindex[i] +queindex[i];
      //   console.log(thisthatid);
      //   console.log(i);


      //   (<any>$('#'+thisthatid)).prop("checked", true);
      // }
      // (<any>$('#this0')).attr('checked', true);

    }

    // this.spinner.hide();
  }

  selectedButton(id, index) {
    // if(this.selectoptionarray.length != 7) {
    //   (<any>$('#next')).prop('disabled', true);
    // }

    if(this.selectoptionarray.includes(id) == true) {
      this.selectoptionarray.splice(this.selectoptionarray.indexOf(id), 1);
      (<any>$('.selectbutton_'+ id)).css("background-color", "");
      localStorage.setItem("fourthquestion", this.selectoptionarray);

      if(this.selectoptionarray.length != 7) {
        // (<any>$('#next')).prop('disabled', true);
      }

      var fourthquelist = localStorage.getItem("fourthquestion");
      var fourthquearray = fourthquelist.split(",");
      this.question_counter = fourthquearray.length;

    } else {

      var fourthquelist = localStorage.getItem("fourthquestion");
      var fourthquearray = fourthquelist.split(",");

      // if(fourthquearray.length <= 7) {
        this.selectoptionarray.push(id);
        (<any>$('.selectbutton_'+ id)).css("background-color", "yellow");
        localStorage.setItem("fourthquestion", this.selectoptionarray);
      // }

      // if(this.selectoptionarray.length <= 7) {

      // }

      var fourthquelist = localStorage.getItem("fourthquestion");
      var fourthquearray = fourthquelist.split(",");
      this.question_counter = fourthquearray.length;

    }
    if(this.selectoptionarray.length == 7) {
      // (<any>$('#next')).prop('disabled', false);
    }
  }

  nextPageRedirect() {
    this.spinner.show();
    var queindexid = localStorage.getItem("sixquestionid");
    var queoptionname = localStorage.getItem("sixquestionname");
    var quedatabaseid = localStorage.getItem("sixquestiondbid");
    var quename = localStorage.getItem("sixquestionvalue");

    var ip_address = localStorage.getItem("ip_address");
    var matchajentid = localStorage.getItem("matchajentid");
    var dataarray = {
      ip_address: ip_address,
      _id : matchajentid,
      sixquestion:quedatabaseid,
      sixquestionname:queoptionname,
    }

    this.questionservice.updatesixQuestion(dataarray).subscribe(result =>{
      if(result.status == true) {
        if(this.indexiarray.length >= this.totaloption) {
          this.router.navigate(['sevenquestion']);
        } else {
          console.log('ankit');
          // debugger;
          this.spinner.hide();
          $('.notebox').text('Please select all options to move ahead');
        }

      }
    });

    if(this.indexiarray.length >= this.totaloption) {

        } else {
          this.spinner.hide();
          $('.notebox').text('Please select all options to move ahead');
        }

    // this.router.navigate(['fifthquestion']);
    // if( localStorage.getItem("sixquestionid") != '') {
    //   this.router.navigate(['sevenquestion']);
    // }
  }

  radiobuttoncheck(i, value, id, thisthatname) {
    console.log(this.indexiarray);
    console.log(i);
    console.log(this.indexiarray.includes(i.toString()));
    // debugger;

    if(this.indexiarray.includes(i) == true)  {
      this.indexiarray.splice(this.indexiarray.indexOf(i), 1);
      this.thisthatnamearray.splice(this.indexiarray.indexOf(i), 1);
      this.thisthatidarray.splice(this.indexiarray.indexOf(i), 1);
      this.thisthatvaluearray.splice(this.indexiarray.indexOf(i), 1);
    }
    // if(this.indexiarray.includes(i.toString()) == true)  {
    //   this.indexiarray.splice(this.indexiarray.indexOf(i), 1);
    //   this.thisthatnamearray.splice(this.indexiarray.indexOf(i), 1);
    //   this.thisthatidarray.splice(this.indexiarray.indexOf(i), 1);
    //   this.thisthatvaluearray.splice(this.indexiarray.indexOf(i), 1);
    // }

    this.indexiarray.push(parseInt(i));
    this.thisthatnamearray.push(thisthatname);
    this.thisthatidarray.push(id);
    this.thisthatvaluearray.push(value);

    localStorage.setItem("sixquestionid", this.indexiarray);
    localStorage.setItem("sixquestionname",  this.thisthatnamearray);
    localStorage.setItem("sixquestiondbid",  this.thisthatidarray);
    localStorage.setItem("sixquestionvalue",  this.thisthatvaluearray);


    if(this.indexiarray.length >= this.totaloption) {
      // (<any>$('#next')).prop('disabled', false);
    }

    // if(thisthatname == 'this') {
    //   var thisvalue= 'this_'+i;
    //   this.thisarray.push(thisvalue);
    //   localStorage.setItem("sixquestionthis", this.thisarray);

    // } else {
    //   var thatvalue= 'this_'+i;
    //   this.thatarray.push(thatvalue);
    //   localStorage.setItem("sixquestionthat", this.thatarray);
    // }

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    setTimeout(function () {
      var queindexid = localStorage.getItem("sixquestionid");
    var queoptionname = localStorage.getItem("sixquestionname");
    var questiondatabaseid = localStorage.getItem("sixquestiondbid");
    var questionvalue = localStorage.getItem("sixquestionvalue");
    if(queindexid != '' && queindexid != null) {
      var queindex = queindexid.split(",");
      var quesindex = queoptionname.split(",");
      var quesdatabaseindex = questiondatabaseid.split(",");
      var quesvalueindex = questionvalue.split(",");

      // console.log('ankit' + queindex.length);
      // for(var i = 0; i<=queindex.length - 1;i++) {

      //   // // if(queindex.length >= i) {
      //   //   var thisthatid = quesindex[i] +queindex[i];
      //   //   console.log(thisthatid);
      //   //   console.log(i);
      //   //   debugger;


      //   //   (<any>$('#'+thisthatid)).prop("checked", true);
      //   // // } else {
      //   // //   break;
      //   // // }

      // }
      // debugger;

      for(var i =0; i<=queindex.length - 1;i++) {

        var thisthatid = quesindex[i] +queindex[i];
        console.log(thisthatid);

        (<any>$('#'+thisthatid)).attr('checked', true);
      }
    }
    }, 1000);


  }

  ngAfterContentChecked() {
    // (<any>$('#this0')).prop('checked', true);
    // (<any>$('#this0')).attr("checked");

    var queindexid = localStorage.getItem("sixquestionid");
    var queoptionname = localStorage.getItem("sixquestionname");
    var questiondatabaseid = localStorage.getItem("sixquestiondbid");
    var questionvalue = localStorage.getItem("sixquestionvalue");
    if(queindexid != '' && queindexid != null) {
      var queindex = queindexid.split(",");
      var quesindex = queoptionname.split(",");
      var quesdatabaseindex = questiondatabaseid.split(",");
      var quesvalueindex = questionvalue.split(",");

      // console.log('ankit' + queindex.length);
      // for(var i = 0; i<=queindex.length - 1;i++) {

      //   // // if(queindex.length >= i) {
      //   //   var thisthatid = quesindex[i] +queindex[i];
      //   //   console.log(thisthatid);
      //   //   console.log(i);
      //   //   debugger;


      //   //   (<any>$('#'+thisthatid)).prop("checked", true);
      //   // // } else {
      //   // //   break;
      //   // // }

      // }
      // debugger;

      for(var i =0; i<=queindex.length - 1;i++) {

        var thisthatid = quesindex[i] +queindex[i];
        // console.log(thisthatid);

        // (<any>$('#'+thisthatid)).attr('checked', true);
      }
    }

  }

  perviousPageRedirect() {
    this.spinner.show();
    this.router.navigate(['fifthquestion']);
  }

  getSixQuestion() {
    this.questionservice.getSixQuestion().subscribe(data => {
      this.question = data['question'][0].question;
      this.description = data['question'][0].description;
      this.items = data['optionData'];
      this.totaloption = this.items.length;


      for(var i = 0; i<=this.items.length; i++) {
        if(i != this.items.length) {
          this.itemcounter.push(i);
        }
      }
    });
  }


}


import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { HttpClientModule } from '@angular/common/http';
import {HomeService} from "../service/home.service";
import { RouterModule, Router, ActivatedRoute  } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';


// import { HttpClientModule } from '@angular/common/http';


// import { AuthService } from "../auth/auth.service";

@Component({
  selector: "app-main",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit   {

  home_banner_bannerimage:any = '';
  home_banner_first_tag_line:any = '';
  home_banner_second_tag_line:any = '';
  home_banner_button_text:any = '';
  home_howwork_title:any = '';
  home_homework_bannerimage:any = '';
  show_home_header:boolean = false;
  private fragment: string;
  href:any;
  hrefarray:any;
  hreffinalarray:any;
  headershowstatus:boolean = false;


  constructor(
    private homeservice: HomeService,
    private router :Router,
    private activeroute :ActivatedRoute,
    private spinner: SpinnerVisibilityService
   ) {
    spinner.show();
  }

  ngOnInit() {


    $('.homeheader').hide();

    // setTimeout(() => {
    //   /** spinner ends after 5 seconds */
    //   this.spinner.hide();
    // }, 5000);
    this.href = window.location.href;
    console.log('href', this.href);
    this.hrefarray= this.href.split('#');
    this.hreffinalarray = this.hrefarray[1].split('/');

    if(this.hreffinalarray[1] == '') {
     this.headershowstatus = true;
    } else {
     this.headershowstatus = false;
    }
    console.log('header status', this.headershowstatus);

    this.activeroute.fragment.subscribe((fragment: string) => {
      if(fragment == 'homwwork') {

      }
  })

  this.activeroute.fragment.subscribe(fragment => { this.fragment = 'homwwork'; });

    // this.router.fragment
    // .subscribe((fragment: string) => {
    //   this.scrollToAnchor(fragment);
    // });

    localStorage.removeItem('firstquestion');
    localStorage.removeItem('secondquestion');
    localStorage.removeItem('thirdquestion');
    localStorage.removeItem('fourthquestion');
    localStorage.removeItem('fifthquestion');
    localStorage.removeItem("sixquestionid");
    localStorage.removeItem("sixquestionname")
    localStorage.removeItem("sixquestiondbid");
    localStorage.removeItem("sixquestionvalue");
    localStorage.removeItem('first_name' );
    localStorage.removeItem('last_name');
    localStorage.removeItem('email_id');
    localStorage.removeItem('country_code');
    localStorage.removeItem('phone');
    localStorage.removeItem('ip_address');

    this.homeservice.getHomeSetting().subscribe(data => {
      console.log( data['data']);
      this.show_home_header = true;
      data['data'].forEach(element => {
        if(element.text_key == "home_banner_bannerimage") {
          this.home_banner_bannerimage = element.text_value;
        }
        if(element.text_key == "home_banner_first_tag_line") {
          this.home_banner_first_tag_line = element.text_value;
        }
        if(element.text_key == "home_banner_second_tag_line") {
          this.home_banner_second_tag_line = element.text_value;
        }

        if(element.text_key == "home_banner_button_text") {
          this.home_banner_button_text = element.text_value;;
        }

        if(element.text_key == "home_howwork_title") {
          this.home_howwork_title = element.text_value;;
        }
        if(element.text_key == "home_homework_bannerimage") {
          this.home_homework_bannerimage = element.text_value;        }
      });
    });
    this.spinner.hide();


  }

  ngAfterViewInit(): void {
    this.activeroute.fragment.subscribe((fragment: string) => {
      if(fragment == 'homwwork') {
        document.getElementById('fragment').scrollIntoView();
      }
   });
  }



  nextPageRedirect() {
   return this.router.navigate(['firstquestion']);
  }
}

import { Component, OnInit, OnDestroy, NgModule, AfterViewChecked } from "@angular/core";
import {QuestionService} from '../service/question.service';
import { RouterModule, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { NgxSpinnerService } from "ngx-spinner";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes,
  group
} from '@angular/animations';
@Component({
  selector: "app-main-que",
  templateUrl: "./fifthquestion.component.html",
  styleUrls: ["./fifthquestion.component.css"],
  animations: [
    trigger('flyInOut',[
        transition("void => *",[
            style({opacity:'0'}),
            animate('2s')
          ]),
        transition("* => void",[
            animate('1s',style({transform:"translateX(100%)"}))
          ])

      ])
  ]
})
export class FifthQuestionComponent implements OnInit {

  question:any = '';
  description:any = '';
  items:any = [];
  itemcounter:any = [];
  status:boolean=true;
  selectoptionarray:any = [];
  question_counter:any = '';

  constructor(private questionservice: QuestionService, private router:Router, private spinner: NgxSpinnerService) {
    spinner.show();
  }

  ngOnInit() {
    $('.notebox').text('');
    $('.homeheader').show();

    $('html,body').scrollTop(0);
    if(localStorage.getItem('firstquestion') == 'null' || localStorage.getItem('firstquestion') == null) {
      this.router.navigate(['']);
    }

    this.question_counter = '0';
    this.getFifthQuestion();
    (<any>$('#next')).prop('disabled', true);
    if(localStorage.getItem("fifthquestion") != '') {
      (<any>$('#next')).prop('disabled', false);
      var firsqueidval = localStorage.getItem("fifthquestion");
    }

    var fifthquelist = localStorage.getItem("fifthquestion");
    if(fifthquelist != '') {
      var fifthquearray = fifthquelist.split(",");
      //
      this.question_counter = fifthquearray.length;
      if(fifthquearray.length >= 2) {
        // (<any>$('#next')).prop('disabled', false);
      } else {
        // (<any>$('#next')).prop('disabled', true);

      }

      fifthquearray.forEach(element => {
        (<any>$('.selectbutton_'+element)).css("background-color", "#ff8033");
        this.selectoptionarray.push(element);
      });
    }

    // this.spinner.hide();

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // this.spinner.hide();
  }

  selectedButton(id, index) {
    // var fifthquelist = localStorage.getItem("fifthhquestion");
    // var fifthquearray = fifthquelist.split(",");
    // if(this.selectoptionarray.length != 7) {
    //   (<any>$('#next')).prop('disabled', true);
    // }


    if(this.selectoptionarray.includes(id) == true) {
      this.selectoptionarray.splice(this.selectoptionarray.indexOf(id), 1);
     (<any>$('.selectbutton_'+id)).css("background-color", "#ffffff");
     (<any>$('.selectbutton_'+ id)).css("color", "#777676");

      localStorage.setItem("fifthquestion", this.selectoptionarray);

      if(this.selectoptionarray.length >= 2 ) {
        // (<any>$('#next')).prop('disabled', true);
      }

      var fifthquelist = localStorage.getItem("fifthquestion");
      if(fifthquelist == '') {
        this.question_counter = 0;
      } else {
        var fifthquearray = fifthquelist.split(",");
        this.question_counter = fifthquearray.length;
      }

    } else {

      if(this.question_counter != 2) {
        this.selectoptionarray.push(id);
        (<any>$('.selectbutton_'+ id)).css("background-color", "#ff8033");
        (<any>$('.selectbutton_'+ id)).css("color", "#fff");
        localStorage.setItem("fifthquestion", this.selectoptionarray);
        var fifthquelist = localStorage.getItem("fifthquestion");
        var fifthquearray = fifthquelist.split(",");
        this.question_counter = fifthquearray.length;
      } else {
        // this.open(mymodal);
        // alert('You have already selected 7 options please unselect one to choose another');
        this.spinner.hide();
        (<any>$('.selectbutton_'+id)).css("background-color", "#ffffff");
        (<any>$('.selectbutton_'+ id)).css("color", "#777676");
        $('.notebox').text('You have already selected 2 options. Please unselect one to choose another');
      }

      // if(this.selectoptionarray.length <= 7) {
      // }
    }

    if(this.question_counter >= 2) {
      // (<any>$('#next')).prop('disabled', false);
    } else {
      // (<any>$('#next')).prop('disabled', true);
    }
  }

  nextPageRedirect() {
    this.spinner.show();
    if( localStorage.getItem("fifthquestion") != '' &&  localStorage.getItem("fifthquestion") != 'null' &&  localStorage.getItem("fifthquestion") != null) {
      var fifthquelist = localStorage.getItem("fifthquestion");
      var ip_address = localStorage.getItem("ip_address");
      var matchajentid = localStorage.getItem("matchajentid");
      var dataarray = {
        ip_address: ip_address,
        _id : matchajentid,
        fifthquestion:fifthquelist,
      }
      this.questionservice.updatefifthQuestion(dataarray).subscribe(result =>{
        if(result.status == true) {
          if(this.question_counter == 2) {
            this.router.navigate(['sixquestion']);
          } else {
            this.spinner.hide();
            $('.notebox').text('Please select at-least 2 options to move ahead');
          }
        }
      });

    } else {
      this.spinner.hide();
      $('.notebox').text('Please select at-least 2 options to move ahead');
    }

    if(this.question_counter == 2) {
    } else {
      this.spinner.hide();
      $('.notebox').text('Please select at-least 2 options to move ahead');
    }
  }

  ngAfterViewChecked() {
    if(localStorage.getItem("fifthquestion") != '') {
      // (<any>$('#next')).prop('disabled', false);
      var fifthquelist = localStorage.getItem("fifthquestion");
      var fifthquearray = fifthquelist.split(",");
      //
      this.question_counter = fifthquearray.length;
      if(fifthquearray.length == 7) {
        (<any>$('#next')).prop('disabled', false);
      }

      fifthquearray.forEach(element => {
        (<any>$('.selectbutton_'+element)).css("background-color", "#ff8033");
        (<any>$('.selectbutton_'+ element)).css("color", "#fff");
        // this.selectoptionarray.push(element);
      });

      // console.log(firsqueidval);
      //
    }
  }


  perviousPageRedirect() {
    this.spinner.show();
    this.router.navigate(['fourthquestion']);
  }


  getFifthQuestion() {
    this.questionservice.getFifthQuestion().subscribe(data => {
      this.spinner.hide();
      this.question = data['question'][0].question;
      this.description = data['question'][0].description;
      this.items = data['optionData'];

      for(var i = 0; i<=this.items.length; i++) {
        if(i != this.items.length) {
          this.itemcounter.push(i);
        }
      }
    });
  }
}


import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { HttpClientModule } from '@angular/common/http';
import {HomeService} from "../service/home.service";
import { RouterModule, Router } from '@angular/router';

// import { HttpClientModule } from '@angular/common/http';


// import { AuthService } from "../auth/auth.service";

@Component({
  selector: "app-main",
  templateUrl: "./ajentprofile.component.html",
  styleUrls: ["./ajentprofile.component.css"]
})
export class AjentProfileComponent implements OnInit   {

  home_banner_bannerimage:any = '';
  home_banner_first_tag_line:any = '';
  home_banner_second_tag_line:any = '';
  home_banner_button_text:any = '';
  home_howwork_title:any = '';
  home_homework_bannerimage:any = '';

  constructor(private homeservice: HomeService,private router :Router) {

  }

  ngOnInit() {
    localStorage.removeItem('firstquestion');
    localStorage.removeItem('secondquestion');
    localStorage.removeItem('thirdquestion');
    localStorage.removeItem('fourthquestion');
    localStorage.removeItem('fifthquestion');
    localStorage.removeItem("sixquestionid");
    localStorage.removeItem("sixquestionname")
    localStorage.removeItem("sixquestiondbid");
    localStorage.removeItem("sixquestionvalue");
    localStorage.removeItem('first_name' );
    localStorage.removeItem('last_name');
    localStorage.removeItem('email_id');
    localStorage.removeItem('country_code');
    localStorage.removeItem('phone');

    this.homeservice.getHomeSetting().subscribe(data => {
      console.log( data['data']);
      data['data'].forEach(element => {
        if(element.text_key == "home_banner_bannerimage") {
          this.home_banner_bannerimage = element.text_value;
        }
        if(element.text_key == "home_banner_first_tag_line") {
          this.home_banner_first_tag_line = element.text_value;
        }
        if(element.text_key == "home_banner_second_tag_line") {
          this.home_banner_second_tag_line = element.text_value;
        }

        if(element.text_key == "home_banner_button_text") {
          this.home_banner_button_text = element.text_value;;
        }

        if(element.text_key == "home_howwork_title") {
          this.home_howwork_title = element.text_value;;
        }
        if(element.text_key == "home_homework_bannerimage") {
          this.home_homework_bannerimage = element.text_value;        }
      });
    });

  }

  nextPageRedirect() {
   return this.router.navigate(['firstquestion']);
  }
}

<!-- <app-main-que-header></app-main-que-header> -->
<!-- <ng-http-loader></ng-http-loader> -->
<section class="section grey_bg">
    <div class="container" [@flyInOut]>
        <div class="row">
            <div class="col-12">
                <div class="que_pagination_container">
                    <div class="que_pagination">
                        <ul>
                            <li class="active"><span>1</span></li>
                            <li class="active"><span>2</span></li>
                            <li class="active"><span>3</span></li>
                            <li class="active"><span>4</span></li>
                            <li class="active"><span>5</span></li>
                            <li class="active"><span>6</span></li>
                            <li class="active"><span>7</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3"></div>
            <div class="col-lg-6" [@flyInOut]>
                <!-- <ngx-spinner></ngx-spinner> -->
                <div class="min_height_funda">
                    <h1 class="que_title">{{ home_information_title }}</h1>
                    <p class="que_desc">{{ home_information_sub_title }}</p>
                </div>
                <form #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()">
                    <div class="form-row">
                        <div class="col-md-6 form-group">
                            <label>First Name</label>
                            <input type="text" name="first_name" [(ngModel)]="model.first_name" #first_name="ngModel" class="form-control" placeholder="Enter first name" [ngClass]="{ 'is-invalid': f.submitted && first_name.invalid }" required>
                            <div *ngIf="f.submitted && first_name.invalid" class="invalid-feedback">
                                <div *ngIf="first_name.errors.required">First Name is required</div>
                            </div>
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Last Name</label>
                            <input type="text" name="last_name" [(ngModel)]="model.last_name" #last_name="ngModel" class="form-control" placeholder="Enter last name" [ngClass]="{ 'is-invalid': f.submitted && last_name.invalid }" required>
                            <div *ngIf="f.submitted && last_name.invalid" class="invalid-feedback">
                                <div *ngIf="last_name.errors.required">Last Name is required</div>
                            </div>
                        </div>
                        <div class="col-md-12 form-group">
                            <label>Email</label>
                            <input type="text" name="email" [(ngModel)]="model.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" class="form-control" placeholder="Enter email" required email>
                            <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                <div *ngIf="email.errors.required">Email is required</div>
                                <div *ngIf="email.errors.email">Email must be a valid email address</div>
                            </div>
                        </div>
                        <div class="col-md-12 form-group">

                            <label>Phone</label>
                            <div class="form-row">
                                <div class="col-2">
                                    <select type="text" [(ngModel)]="model.country_code" #country_code="ngModel" name="country_code" class="form-control" placeholder="Enter number">
                                  <option value="+1" >+1</option>
                                  <option value="+91">+91</option>
                                  </select>
                                </div>
                                <div class="col-10">
                                    <input type="text" name="phone" [(ngModel)]="model.phone" #phone="ngModel" [ngClass]="{ 'is-invalid': f.submitted && phone.invalid }" required class="form-control" placeholder="Enter Phone number">
                                    <div *ngIf="f.submitted && phone.invalid" class="invalid-feedback">
                                        <div *ngIf="phone.errors.required">Phone Number is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="next_perv_btndiv">
                        <div class="row">
                            <div class="col-sm-6 text-center text-sm-left">
                                <button (click)="perviousPageRedirect()" class="theme_btn next_prev_btn prev_btn"><i class="fas fa-arrow-left"></i> Previous</button>
                            </div>
                            <div class="col-sm-6 text-center text-sm-right">
                                <button id="next" type="submit" class="theme_btn next_prev_btn next_btn">Get Matched </button>
                                <!-- <button id="next" type="submit" class="theme_btn_get next_prev_btn next_btn">Get Matched </button> -->
                            </div>
                        </div>
                    </div>
                </form>

            </div>
            <div class="col-lg-3"></div>
        </div>
    </div>
</section>
<div class="footer_section">
    <div class="container">
        <div class="footer">
            <ul>
                <li><a href="https://cityworthhomes.com/about-us/" target="_blank">About</a></li>
                <li><a href="https://cityworthhomes.com/terms-and-conditions/" target="_blank">Terms & Conditions</a></li>
                <li><a href="https://cityworthhomes.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
                <li><a href="https://cityworthhomes.com/contact-cityworth/" target="_blank">Contact Us</a></li>
            </ul>
            <p class="copy_text">Copyright © 2020 Venture By <a href="https://cityworthhomes.com/" target="_blank">CityWorth Homes </a></p>
        </div>
    </div>
</div>
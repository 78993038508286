<!-- <app-main-que-header></app-main-que-header> -->
<!-- <ng-http-loader></ng-http-loader> -->
<section class="section grey_bg">
    <div class="container" [@flyInOut]>
        <div class="row">
            <div class="col-12">
                <div class="que_pagination_container">
                    <div class="que_pagination">
                        <ul>
                            <li class="active"><span>1</span></li>
                            <li class="active"><span>2</span></li>
                            <li><span>3</span></li>
                            <li><span>4</span></li>
                            <li><span>5</span></li>
                            <li><span>6</span></li>
                            <li><span>7</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
            <div class="col-lg-8 min_height_funda" [@flyInOut]>
                <!-- <ngx-spinner></ngx-spinner> -->
                <div class="min_height_funda">
                    <h1 class="que_title">{{ question }}</h1>
                    <p class="que_desc">{{ description }}</p>
                    <p class="que_desc notebox" style="color: red;"></p>
                </div>
                <div class="que_ans_container ">
                    <div class="row">
                        <div class="col-12 text-center" *ngFor="let item of itemcounter;  let i = index">
                            <button (click)="selectedButton(items[item]['_id'], i )" class="selectbutton_{{ items[item]['_id'] }}  unselectbutton" id="{{ items[item]['_id'] }}" class="theme_btn big_btn que_btn">{{ items[item]['option'] }}</button>
                        </div>
                    </div>
                </div>

                <div class="next_perv_btndiv">
                    <div class="row">
                        <div class="col-sm-6 text-center text-sm-left">
                            <button (click)="perviousPageRedirect()" class="theme_btn next_prev_btn prev_btn"><i class="fas fa-arrow-left"></i> Previous</button>
                        </div>
                        <div class="col-sm-6 text-center text-sm-right">
                            <button id="next" (click)="nextPageRedirect()" class="theme_btn next_prev_btn next_btn">next <i class="fas fa-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>
</section>
<!-- <app-main-que-header></app-main-que-header> -->
<!-- <ng-http-loader></ng-http-loader> -->
<section class="banner_video">
    <!-- <button class="theme_btn orange_btn" (click)="open(myvideomodal)">Share</button> -->
    <div [ngStyle]="{'background': 'url(' + home_banner_bannerimage + ') no-repeat 0 0 / cover'}" class="agent_banner_main home_banner bg">
        
    </div>
</section>

<div class="agent_detail equal_height">
    <div class="agent_sidebar equal_height_container">
        <div class="agent_sidebar_content">
            <div class="top_agent_sidebar">
                <div class="profile_img_box_container">
                    <div class="profile_img_box">
                        <div class="profile_img wow zoomIn">
                            <img [src]=" realtordata[0]['photo'] ">
                        </div>
                        <img *ngIf="video != ''"  src="./../assets/images/orange_play_btn.png" (click)="openandplay(myvideomodal)" class="video_ply_btn">
                    </div>
                </div>
                <div class="mobile_view_agent_name">
                    <p class="agent_name wow slideInLeft">{{ realtordata[0]['first_name'] }} {{ realtordata[0]['last_name'] }}</p>
                    <p class="agent_type">REALTOR©</p>
                </div>

                <div class="share_section">
                    <p>
                        <a href="{{'tel:'+realtordata[0]['country_code']+realtordata[0]['phone']}}" class="socila_icon phone_icon"><i class="fas fa-phone"></i>{{realtordata[0]['country_code']+realtordata[0]['phone']}}</a>
                    </p>
                    <p>
                        <a href="{{ 'mailto:'+ realtordata[0]['email']}}" class="socila_icon"><i class="fas fa-envelope"></i>{{realtordata[0]['email']}}</a>
                    </p>
                </div>
            </div>
            
            <hr class="agent_sidebar_divider">
            <div class="bottom_agent_sidebar">
                <div class="text-center">
                    <div class="rating_section">
                        <div class="rating_counter">{{(realtordata[0]['ratings']).toFixed(1)}}</div>
                        <div class="rating_conter_text">
                            <div class="start_section">
                                <!-- <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i> -->
                                <div class="stars-outer">
                                  <div style="width: {{starRating}}%"  class="stars-inner"></div>
                                </div>
                            </div>
                            <p>{{realtordata[0]['reviews']}} Reviews</p>
                        </div>
                    </div>
                </div>
                <div class="button_section">
                    <h4>Licensed In:</h4>
                    <!--  -->
                    <div class="col-main">
                        <div class="for-4-btn" *ngIf="statedata.length<=4">
                            <div class="btn_col">
                                <div *ngFor="let item of statedata;let i = index" style="width: 100%">
                                    <button class="theme_btn no_hover  ">{{ statedata[i]['option'] }}</button>
                                </div>
                            </div>
                        </div>
                        <div class="more-4-btn" *ngIf="statedata.length>4">
                            <div class="btn_row">
                                <div *ngFor="let item of statedata;let i = index" class="btn_row_single">
                                    <button class="theme_btn no_hover  ">{{ statedata[i]['option'] }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="agent_detail_container equal_height_container">
        <div class="agent_detail_container_content">
            <div class="web_view_agent_name">
                <p class="agent_name wow slideInLeft">{{ realtordata[0]['first_name'] }} {{ realtordata[0]['last_name'] }}</p>
                <p class="agent_type">REALTOR©</p>
            </div>
            
            <!-- <p class="congo_text">"Congratulations, you’ve been matched with <span>{{ realtordata[0]['first_name'] }} {{ realtordata[0]['last_name'] }}</span>". {{ realtordata[0]['greeting_message'] }}</p> -->
            <!-- <p class="congo_text" id="congo_text_print">{{ greeting_message }}</p> -->
            <p class="congo_text" id="congo_text_print"></p>
            <div class="row equal_height">
                <div class="col-lg-8 equal_height_container">
                    <div class="grey_bg grey_detail_box">
                        <span [innerHTML]="realtordata[0]['bio'] "></span>
                    </div>
                </div>
                <div class="col-lg-4 equal_height_container">
                    <div class="grey_bg grey_detail_box">
                        <h4>Interests :</h4>
                        <ul class="interest_ul">
                            <p class="interest_p" *ngFor="let item of intrestedselectedarray;let i = index">
                                <li> {{ intrestedselectedarray[i]['option'] }}</li>
                            </p>
                        </ul>
                    </div>
                </div>
            </div>
            
            
            <ng-template #mymodal let-modal>

                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Social Share</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
                </div>
                <div class="modal-body">
                    <div class="social_btns">
                        <div class="btns">
                            <div [socialShare]="'fb'" class="fb"><i class="fab fa-facebook"></i><span>Facebook</span></div>
                            <div>
                                <a [href]="twitter_url" data-size="large" target="_blank" style="color: inherit;"><i class="fab fa-twitter"></i><span>Twitter</span></a></div>
                            <div [socialShare]="'in'" class="in"><i class="fab fa-linkedin"></i><span>Linkedin</span></div>
                        </div>
                        <div class="btns">
                            <div [socialShare]="'tr'" class="tr"><i class="fab fa-tumblr-square"></i><span>Tumblr</span></div>
                            <!-- <div [socialShare]="'rd'" class="rd"><i class="fab fa-reddit-square"></i><span>Reddit</span></div> -->
                            <div><a [href]="reddit_url" target="_blank"><i class="fab fa-reddit-square"></i><span>Reddit</span></a></div>
                            <div><a [href]="pintrest_url" data-pin-url="current_url" data-pin-do="buttonPin" data-pin-config="beside" target="_blank"><i class="fab fa-pinterest-square"></i><span>Pinterest</span></a></div>

                            <!-- data-pin-custom="true" -->
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="theme_btn orange_btn" (click)="modal.close('Save click')">Ok</button>
                </div>
            </ng-template>
            <input type="hidden" name="youtubestatus" id="youtubestatus" [value]="youtubestatusNum">
            <ng-template #myvideomodal let-modal>
                <div class="modal-header">
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <iframe *ngIf="video != '' && youtubestatus == true" [src]="video" id="playvideo" width="560" height="315" frameborder="0" autoplay></iframe>
                    <video *ngIf="youtubestatus == false" [src]="video" id="playvideo" controls width="560" height="315"></video>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-lg-8">
                    <div class="text-center auth_button_div">
                        <a href="{{ home_information_get_pre_approval }}" target="_blank" class="theme_btn orange_btn">Get a Pre-Approval</a>
                        <button class="theme_btn orange_btn" (click)="open(mymodal)">Share</button>
                    </div>
                </div>
                <div class="col-lg-4"></div>
            </div>
        </div>
    </div>
</div>

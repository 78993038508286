import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FirstQuestionComponent } from './firstquestion/firstquestion.component';
import { SecondQuestionComponent } from './secondquestion/secondquestion.component';
import { ThirdQuestionComponent } from './thirdquestion/thirdquestion.component';
import { FourthQuestionComponent } from './fourthquestion/fourthquestion.component';
import { FifthQuestionComponent } from './fifthquestion/fifthquestion.component';
import { SixQuestionComponent } from './sixquestion/sixquestion.component';
import { SevenQuestionComponent } from './sevenquestion/sevenquestion.component';
import { AjentProfileComponent } from './ajentprofile/ajentprofile.component';
import { MatchAjentComponent } from './matchajent/matchajent.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "firstquestion", component: FirstQuestionComponent},
  { path: "secondquestion", component: SecondQuestionComponent},
  { path: "thirdquestion", component: ThirdQuestionComponent},
  { path: "fourthquestion", component: FourthQuestionComponent},
  { path: "fifthquestion", component: FifthQuestionComponent},
  { path: "sixquestion", component: SixQuestionComponent},
  { path: "sevenquestion", component: SevenQuestionComponent},
  { path: "ajentdetail", component: AjentProfileComponent},
  { path: "agent\/:name", component: MatchAjentComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule, FormsModule, CommonModule]
})
export class AppRoutingModule { }

import { Component, OnInit, OnDestroy, NgModule, AfterViewChecked } from "@angular/core";
import {HomeService} from '../service/home.service';
import { RouterModule, Router } from '@angular/router';
import {  FormGroup, FormControl, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Helpers } from "../helper";
import { SpinnerVisibilityService } from 'ng-http-loader';
import { json } from 'body-parser';
import { NgxSpinnerService } from "ngx-spinner";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes,
  group
} from '@angular/animations';
const httpOptions = {
  headers: new Headers({ 'Content-Type': 'application/json' })
};

@Component({
  selector: "app-main-que",
  templateUrl: "./sevenquestion.component.html",
  styleUrls: ["./sevenquestion.component.css"],
  animations: [
    trigger('flyInOut',[
        transition("void => *",[
            style({opacity:'0'}),
            animate('2s')
          ]),
        transition("* => void",[
            animate('1s',style({transform:"translateX(100%)"}))
          ])

      ])
  ]
})


export class SevenQuestionComponent implements OnInit {

  home_information_title:any = '';
  home_information_sub_title:any = '';
  // form:FormGroup;
  model: any = {};
  item:any = [];


  constructor(private homeservice: HomeService, private router:Router, private http: HttpClient,private spinner: NgxSpinnerService) {
    spinner.show();
  }

  ngOnInit() {
    $('.homeheader').show();

    $('html,body').scrollTop(0);
    if(localStorage.getItem('firstquestion') == 'null' || localStorage.getItem('firstquestion') == null) {
      this.router.navigate(['']);
    }

    this.model.country_code = '+1';

    this.homesetting();
    // this.form.value.first_name = 'asf';

    if(localStorage.getItem('first_name') != '') {
      this.model.first_name = localStorage.getItem('first_name');
    }
     if(localStorage.getItem('last_name') != '') {
      this.model.last_name = localStorage.getItem('last_name');
    }
    if(localStorage.getItem('email_id') != '') {
      this.model.email = localStorage.getItem('email_id');
    }
    if(localStorage.getItem('country_code') != null ) {
      this.model.country_code = localStorage.getItem('country_code');
    } else {
      this.model.country_code = '+1';
    }
    if(localStorage.getItem('phone') != '') {
      this.model.phone = localStorage.getItem('phone');
    }

    
  }

  nextPageRedirect() {
    this.spinner.show();
    this.router.navigate(['fifthquestion']);
    if( localStorage.getItem("fourthquestion") != '') {
      this.router.navigate(['sixquestion']);
    }
  }

  homesetting(){
    this.homeservice.getHomeSetting().subscribe(data => {
      console.log(data['data']);
      this.spinner.hide();
      data['data'].forEach(element => {

        if(element.text_key == "home_information_title") {
          this.home_information_title = element.text_value;
        }

        if(element.text_key == "home_information_sub_title") {
          this.home_information_sub_title = element.text_value;
        }
      });
    });
  }

  perviousPageRedirect() {
    this.spinner.show();
    this.router.navigate(['sixquestion']);
  }

  onSubmit(){
    this.spinner.show();
    localStorage.setItem('first_name',  this.model.first_name );
    localStorage.setItem('last_name',  this.model.last_name);
    localStorage.setItem('email_id',  this.model.email);
    localStorage.setItem('country_code',  this.model.country_code);
    localStorage.setItem('phone', this.model.phone);


    var firstquestion_array = [];
    var firstquestion_data = localStorage.getItem('firstquestion');
    var secondquestion_data =localStorage.getItem('secondquestion');
    var thirdquestion_data = localStorage.getItem('thirdquestion');
    var fourthquestion_data = localStorage.getItem('fourthquestion');
    var fifthquestion_data = localStorage.getItem('fifthquestion');
    var sixquestion_data = localStorage.getItem('sixquestiondbid');
    var sixquestion_data = localStorage.getItem('sixquestiondbid');
    var sixquestionname_data = localStorage.getItem('sixquestionvalue');

    if(fourthquestion_data != '') {
     var fourthquestionarray_data = fourthquestion_data.split(',');
    }

    if(fifthquestion_data != '') {
      var fifthquestionarray_data = fifthquestion_data.split(',');
     }

     if(sixquestion_data != '') {
      var sixquestionarray_data = sixquestion_data.split(',');
     }

     var ip_address = localStorage.getItem("ip_address");
     var matchajentid = localStorage.getItem("matchajentid");

     var email_log= [{
      firstquestion_data: firstquestion_data,
      secondquestion_data: secondquestion_data,
      thirdquestion_data: thirdquestion_data,
      fourthquestion_data: fourthquestion_data,
      fifthquestion_data: fifthquestion_data,
      sixquestion_data: sixquestion_data,
      sixquestionname_data: sixquestionname_data,
     }];

     var jsondata = {
       first_name: this.model.first_name,
       last_name: this.model.last_name,
       email: this.model.email,
       phone:this.model.country_code+ this.model.phone,
     }

    this.http.post<any>(Helpers.apipath+'matchrealtor', {
      // firstquestion:firstquestion_data,
      // secondquestion:secondquestion_data,
      // thirdquestion:thirdquestion_data,
      // fourthquestion:fourthquestionarray_data,
      // fifthquestion:fifthquestionarray_data,
      // sixquestion:sixquestionarray_data,
        sevenquestion: JSON.stringify(jsondata),
        ip_address: ip_address,
        _id : matchajentid,
        first_name: this.model.first_name,
        last_name: this.model.last_name,
        email: this.model.email,
        phone:this.model.country_code+ this.model.phone,
        email_log:email_log
    }, ).subscribe(result => {
      if(result['status'] == true) {
        this.spinner.hide();
        this.router.navigate(['agent/'+result['data'] ]);
      }
  });

    // localStorage.getItem("sixquestionid");
    // localStorage.getItem("sixquestionname")
    // localStorage.getItem("sixquestiondbid");
    // localStorage.getItem("sixquestionvalue");

  }
}

